import React from 'react';
import './cta.css';

const CTA = () => (
  <div className="gpt3__cta">
    <div className="gpt3__cta-content">
      <p>Join the Bob Schneck Legacy Mission</p>
      <h3>Join us Today in making better lives. It is all about giving with heart.</h3>
    </div>
    <div className="gpt3__cta-btn">
      <a target="_blank" rel="noopener noreferrer" href="https://mchanga.africa/fundraiser/64039">
        <button type="button">Donate</button>
      </a>
    </div>
  </div>
);

export default CTA;
