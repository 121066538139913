import React from 'react';
import Article from '../../components/article/Article';
import { blog01, blog02, blog03, blog04, blog05, blog06, blog07, blog08, blog09, blog10 } from './imports';
import './blog.css';

const Blog = () => (
  <div className="gpt3__blog section__padding" id="blog">
    <div className="gpt3__blog-heading">
      <h1 className="gradient__text">A lot is happening, <br /> Here is our journey in pictures.</h1>
    </div>
    <div className="gpt3__blog-container">
      {/* <div className="gpt3__blog-container_groupA">
        <Article imgUrl={blog06} date="June, 2023" text="Donation to Vulnerable Pupils in Village School." />
        <Article imgUrl={blog01} date="Dec, 2022" text="Mercy buying solar lamps to donate to students." />
      </div> */}
      <div className="gpt3__blog-container_groupB">
        <Article imgUrl={blog06} date="June, 2023" text="Donation to Vulnerable Pupils in Village School." />
        <Article imgUrl={blog01} date="Dec, 2022" text="Mercy buying solar lamps to donate to students." />
        <Article imgUrl={blog08} date="Jan, 2023" text="Building Partnerships." />
        <Article imgUrl={blog07} date="Feb, 2023" text="Donation to Vulnerable Pupils in Village School." />
        <Article imgUrl={blog09} date="April, 2023" text="Community Training." />
        <Article imgUrl={blog10} date="May, 2023" text="Improving Maternal Health." />
        <Article imgUrl={blog02} date="Oct, 2022" text="Charles and his solar light" />
        <Article imgUrl={blog03} date="Dec, 2022" text="Westlight Solar Shop." />
        <Article imgUrl={blog04} date="Nov, 2022" text="Luci, a student using solar light to study." />
        <Article imgUrl={blog05} date="Jan, 2023" text="Changing lives through clean lighting: A solar light replacing the toxic paraffin lamp." />
      </div>
    </div>
  </div>
);

export default Blog;
