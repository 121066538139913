import React from 'react';
import Feature from '../../components/feature/Feature';
import './sdg.css';

const sdg = () => (
  <div className="gpt3__whatgpt3 section__margin" id="sdg">
    <div className="gpt3__whatgpt3-feature">
      <Feature
        title="UN SDG's"
        text="WestLights goal aligns with the United Nations Sustainable Development Goals UN SDGs number 7 and 1, Affordable and Clean Energy and Ending Poverty in All its Forms Everywhere."
      />
    </div>
    <div className="gpt3__whatgpt3-heading">
      <h1 className="gradient__text">UN Sustainable Development Goals we align with;</h1>
      {/* <p>...what needs to happen!</p> */}
    </div>
    <div className="gpt3__whatgpt3-container">
      <Feature
        title="SDG 1"
        text="By 2030, eradicate extreme poverty for all people everywhere, currently measured as people living on less than $1.25 a day."
      />
      <Feature
        title="SDG 7"
        text="Ensure access to affordable, reliable, sustainable and modern energy for all."
      />
    </div>
  </div>
);

export default sdg;
