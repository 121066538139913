import React from 'react';
// import people from '../../assets/people.png';
import home2 from '../../assets/home2.jpg';
import Shneck from '../../assets/Shneck.jpg';
import './header.css';
import SchneckReflectionsPdf from '../../assets/Burial-Schneck-Reflections.pdf';

const Header = () => (
  <div className="gpt3__header section__padding" id="home">
    <div className="gpt3__header-content">
      <h1 className="gradient__text">WestLight Solar Kenya</h1>
      <p>Facilitate access to clean and affordable energy for the energy poor and disadvantaged rural communities in Vihiga County of Western Kenya; to enrich the scientific education of the youth; and to restore the land.</p>

      <div className="gpt3__header-content__people">
        <img src={Shneck} />
        <p>In honor of Bob Schneck - Support Westlight Solar, community solar light project.</p>
      </div>

      <div className="gpt3__header-content__input">
        {/* <a target="_blank" rel="noopener noreferrer" href="https://mchanga.africa/fundraiser/64039">
          <button type="button">Donate</button>
        </a>
        <br /> */}
        <a className="gpt3__pdf" target="_blank" rel="noopener noreferrer" href={SchneckReflectionsPdf}>
          <button type="button">Schneck Reflections Pdf</button>
        </a>
      </div>

    </div>

    <div className="gpt3__header-image">
      <img src={home2} />
    </div>
  </div>
);

export default Header;
