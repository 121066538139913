import React from 'react';
import Feature from '../../components/feature/Feature';
// import FeatureList from '../../components/featureWithList/FeatureList';
import './walkathon.css';
import solarwalkathon2024 from '../../assets/solarwalkathon2024.jpg';
import shirt from '../../assets/shirt.png';
import cap from '../../assets/cap.png';
import bag from '../../assets/bag.png';

const Walkathon = () => (
  <div className="gpt3__whatgpt3 section__margin" id="walkathon">
    <div className="gpt3__whatgpt3-container">
      {/* <Feature
        title="Its all about..."
        text="It’s all about smiles.
        Helping the fishermen.
        And the moms. And the kids. Better lighting makes better lives. More prosperity."
      /> */}
      <div className="gpt3__map-image">
        <img src={solarwalkathon2024} />
      </div>
      {/* <Feature
        title="The picture"
        text="One final picture and what do we need to get going? This picture sums things up. A $2.00 / day man, a
        kerosene lamp and a solar light. What’s the difference we all can make for the future?"
      /> */}
      {/* <FeatureList
        title="To Grow we need..."
        // text="
      /> */}
    </div>
    <br />
    <div className="gpt3__whatgpt3-feature">
      <Feature
        title="Goal: Kshs.46 million ($308,000)"
        text="WestLight Solar CBO is organizing a Solar Walkathon in May, 2024 to raise awareness about the benefits of solar energy and collect funds to provide solar lanterns and clean energy cooking stoves to 14,000 vulnerable members of the rural community and especially children, women and persons living with disabilities (PLWD) in Western Kenya. This clean energy provision for lighting and cooking addresses five aspects: Respiratory health, Maternal Health, savings on Energy Costs and Climate Change mitigation."
      />
    </div>
    <div className="gpt3__whatgpt3-container">
      <div className="gpt3__map-image">
        <img src={shirt} />
      </div>
      <div className="gpt3__map-image">
        <img src={cap} />
      </div>
      <div className="gpt3__map-image">
        <img src={bag} />
      </div>
    </div>
    <br />
    <div className="gpt3__footer-btn">
      {/* <p>Request Early Access</p> */}
      <a target="_blank" rel="noopener noreferrer" href="https://mchanga.africa/fundraiser/64039">
        <p>Donate</p>
      </a>
    </div>
  </div>
);

export default Walkathon;
