/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import Slider from 'react-slick';
import { Sallatek, paypal, mpesa, davis, equity, totalenergies } from './imports';
import './brand.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const settings = {
  dots: false,
  infinite: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  speed: 2000,
  autoplaySpeed: 2000,
  cssEase: 'linear',
};

const Brand = () => (
  <div className="gpt3__brand section__padding" id="partners">
    <Slider className="Slider" {...settings}>
      <div>
        <img src={equity} />
      </div>
      <div>
        <img src={Sallatek} />
      </div>
      <div>
        <img src={paypal} />
      </div>
      <div>
        <img src={mpesa} />
      </div>
      <div>
        <img src={davis} />
      </div>
      <div>
        <img src={totalenergies} />
      </div>
    </Slider>
  </div>
);

export default Brand;
