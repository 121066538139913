import React from 'react';
import Feature from '../../components/feature/Feature';
import './features.css';

const featuresData = [
  {
    title: 'Raise awareness',
    text: 'Raise awareness of the health risks associated with using paraffin for lighting and cooking, presenting solar for lighting or cooking as a healthy, cost-effective and ecologically sound alternative.',
  },
  {
    title: 'Broaden the Revolving Fund',
    text: 'Grow the Revolving Fund that anchors the project’s sustainability base and facilitate financing cycles for purchase and distribution of solar lanterns and introduce clean cooking stoves to an increasing number of community members.',
  },
  {
    title: 'Build an affirmative action mechanism',
    text: 'Build an affirmative action mechanism to address energy poverty amongst the vulnerable members of the community especially the most economically disadvantaged and those living with disabilities.',
  },
  {
    title: 'Lobby',
    text: 'Actively lobby local and national agencies to influence anti-poverty policies and access to clean and affordable energy.',
  },
  {
    title: 'Promoting solar technology education',
    text: 'Promote the uptake of solar technologies and innovations in STEM schools, village polytechnics/Vocational Training Colleges and local universities through presentations, science fairs and solar technology print materials.',
  },
  {
    title: 'Value proposition',
    text: 'Demonstrate the value of small business entrepreneurship in the region and the quality-of-life advantage of small-scale capital investment to the people.',
  },
  {
    title: 'Build Capacity',
    text: 'Build capacity in solar technologies as well as a foundation for the establishment of a local solar equipment assembly plant in Western Kenya for the production of affordable solar kits for the African market.',
  },
  {
    title: 'Grow',
    text: 'Grow programmes to address the human needs of reducing poverty through economic interventions and advancing solar energy usage.',
  },
];

const Features = () => (
  <div className="gpt3__features section__padding" id="features">
    <div className="gpt3__features-heading">
      <h1 className="gradient__text">To stamp out the use of kerosene for lighting and cooking; fundamentally improving the economic well-being, health and education of rural communties.</h1>
      <p>Our other Objectves are:</p>
    </div>
    <div className="gpt3__features-container">
      {featuresData.map((item, index) => (
        <Feature title={item.title} text={item.text} key={item.title + index} />
      ))}
    </div>
  </div>
);

export default Features;
