import React from 'react';
import Feature from '../../components/feature/Feature';
import FeatureList from '../../components/featureWithList/FeatureList';
import './whatGPT3.css';
import mapOfWesternKenya from '../../assets/mapOfWesternKenya.jpg';

const WhatGPT3 = () => (
  <div className="gpt3__whatgpt3 section__margin" id="wgpt3">
    <div className="gpt3__whatgpt3-feature">
      <Feature
        title="Who are we?"
        text="WestLight Solar CBO is a registered community benefit organisation purposed to raise awareness about clean and affordable energy through promotion of renewable energy technologies as well as tackling energy poverty through provision of solar powered lanterns and clean cooking stoves."
      />
    </div>
    <div className="gpt3__whatgpt3-heading">
      <h1 className="gradient__text">A mission is a dream and prayer. WestLight Solar Project is transforming lives of rural communities as well as fisherfolk in Western Kenya</h1>
      <p>...what needs to happen!</p>
    </div>
    <div className="gpt3__whatgpt3-container">
      {/* <Feature
        title="Its all about..."
        text="It’s all about smiles.
        Helping the fishermen.
        And the moms. And the kids. Better lighting makes better lives. More prosperity."
      /> */}
      <div className="gpt3__map-image">
        <img src={mapOfWesternKenya} />
      </div>
      {/* <Feature
        title="The picture"
        text="One final picture and what do we need to get going? This picture sums things up. A $2.00 / day man, a
        kerosene lamp and a solar light. What’s the difference we all can make for the future?"
      /> */}
      <FeatureList
        title="To Grow we need..."
        // text="
      />
    </div>
  </div>
);

export default WhatGPT3;
