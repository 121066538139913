import React from 'react';
import './featureList.css';

const FeatureList = ({ title, text }) => (
  <div className="gpt3__features-container__feature">
    <div className="gpt3__features-container__feature-title">
      <div />
      <h1>{title}</h1>
    </div>
    <div className="gpt3__features-container_feature-text">
      <p>{text}</p>
      <p>
        <li>
          Grant and philanthropy Advisors
        </li>
        <li>
          Donors
        </li>
        <li>
          Donor Organizations
        </li>
        <li>
          Building of Sustainable Income Generation Activities
        </li>
        <li>
          A robust and sustainable Revolving Fund
        </li>
        <li>
          Partner organizations in clean energies
        </li>
        <li>
          Capacity building in technical areas
        </li>
        <li>
          Partners to promote solar education technologies in schools : books &amp; training
        </li>
        <li>
          Resources to retain and maintain project specialists and solar technicians
        </li>
        <li>
          Resources for community trainings and workshops
        </li>
      </p>
    </div>
  </div>
);

export default FeatureList;
