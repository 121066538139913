import React from 'react';
import Feature from '../../components/feature/Feature';
import './gifts.css';

const featuresData = [
  {
    title: '$8 (Kshs.800)',
    text: 'One lantern for an individual e.g. student.',
  },
  {
    title: '$40 (Kshs.4000)',
    text: 'One lantern for individual with phone charger system.',
  },
  {
    title: '$1,500 (Kshs.150,000)',
    text: 'Solarising a building/house: Lighting, electronics e.g.TV/ computer.',
  },
  {
    title: '$2,000 (Kshs.200,000)',
    text: 'Solarising a building/house: Lighting, Electronics and Fridge.',
  },
];

const Gifts = () => (
  <div className="gpt3__features section__padding" id="gifts">
    <div className="gpt3__features-heading">
      <h1 className="gradient__text">Gift through Mpesa Paybill : 247247 Account No. 740860.</h1>
      <p>Every solar makes a difference.</p>
    </div>
    <div className="gpt3__features-container">
      {featuresData.map((item, index) => (
        <Feature title={item.title} text={item.text} key={item.title + index} />
      ))}
    </div>
  </div>
);

export default Gifts;
